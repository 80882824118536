import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSubmissionsDataApi } from '../api/submissions-database';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import ExportIcon from '../components/icons/ExportIcon';
import PlusIcon from '../components/icons/PlusIcon';
import { SpinnerSmall } from '../components/spinner/Spinner';
import { MEDIA_TYPES, STATUS } from '../constants';
import useListStore from '../store/list-generator';
import { exportLists } from '../utils/exportLists';
import ListGeneratorAutocomplete from './ListGeneratorAutocomplete';
import ListGeneratorSelectors from './ListGeneratorSelectors';
import ListSection from './ListSection';
import { SparklesIcon } from '../components/icons/icons';
import { getDescription } from '../idea-generator/IdeaGeneratorHome';

export const LIST_TYPES = [
  {
    _id: 'writer',
    name: 'Writer'
  },
  {
    _id: 'director',
    name: 'Director'
  },
  {
    _id: 'cast',
    name: 'Cast'
  }
];
export function projectToState(project) {
  return {
    name: `${project.title} (${project.release_date?.split('-')?.[0]})`,
    type: MEDIA_TYPES.find((media) => media.id === project.media_type) || MEDIA_TYPES[0]
  };
}
const ListGeneratorHome = () => {
  const navigate = useNavigate();
  const [sections, addSection] = useListStore((store) => [store.sections, store.addSection]);
  const [dropdown] = useListStore((store) => [store.dropdown, store.setDropdown]);
  const addTalent = useListStore((store) => store.addTalent);
  const isLoading = useListStore((store) => store.isLoading);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submissionsLoading, setSubmissionsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setSubmissionsLoading(true);
      const { data, status } = await getSubmissionsDataApi(location);
      if (status === 200) setSubmissions(data?.data);
      setSubmissionsLoading(false);
    }
    getData();
  }, [location]);
  const description = getDescription(dropdown);
  return (
    <div className="flex flex-col gap-6 font-inter">
      <Card className={'min-h-fit flex gap-4'}>
        <ListGeneratorAutocomplete
          type={dropdown.type?._id}
          onAdd={(result) => {
            addTalent(sections?.[0]?.id, {
              _id: result?._id,
              name: result?.name,
              avatar: `https://image.tmdb.org/t/p/original/${result?.profile_path}`,
              credits: result?.projects?.past_projects?.map(projectToState),
              upcoming_projects: result?.projects?.upcoming_projects?.map(projectToState),
              availability: STATUS[1]?.id
            });
          }}
        />
        <Button
          disabled={!dropdown.version?._id}
          onClick={() => navigate('/list-builder/idea')}
          className={'h-[40px] rounded-lg'}>
          <div className="w-max flex items-center gap-2">
            <SparklesIcon /> Idea Generator
          </div>
        </Button>
      </Card>
      <ListGeneratorSelectors
        submissionsLoading={submissionsLoading}
        submissions={submissions}
        description={description}>
        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);
            exportLists(sections);
            setLoading(false);
          }}>
          <ExportIcon />
          Export List
        </Button>
      </ListGeneratorSelectors>

      {isLoading ? (
        <div className="flex items-center justify-center h-[60vh]">
          <div className="flex flex-col gap-2 items-center justify-center">
            <SpinnerSmall secondPathColor="#FE6629" className="!h-10 !w-10 mr-2" />
            <h2>Loading...</h2>
          </div>
        </div>
      ) : (
        dropdown.version && (
          <>
            {sections.map((section, idx) => {
              return (
                <ListSection
                  {...section}
                  prevSectionId={sections[idx - 1]?.id || null}
                  nextSectionId={sections[idx + 1]?.id || null}
                />
              );
            })}

            <Card className={'min-h-fit'} resetZIndex>
              <Button onClick={() => addSection()} variant="gray">
                <PlusIcon />
                Create Section
              </Button>
            </Card>
          </>
        )
      )}
    </div>
  );
};

export default ListGeneratorHome;

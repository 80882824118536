import React from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './Progress.module.css';
export default function Progress({ value, max = 100, label, className, striped = false }) {
  const progressPercentage = Math.min(Math.max(value, 0), max);
  if (progressPercentage <= 0) return null;

  return (
    <div className={twMerge('w-full', className)}>
      {label && (
        <div className="text-sm font-medium text-white mb-1" id="progress-label">
          {label}
        </div>
      )}
      <div
        className="w-full rounded-lg h-[10px] relative overflow-hidden"
        style={{
          background:
            'linear-gradient(253.98deg, rgba(224, 76, 17, 0.3) 22.31%, rgba(254, 102, 41, 0.3) 77.69%)'
        }}
        role="progressbar"
        aria-valuenow={progressPercentage}
        aria-valuemin={0}
        aria-valuemax={max}
        aria-labelledby={label ? 'progress-label' : undefined}>
        <div
          className={twMerge(
            'h-[10px] rounded-lg transition-all duration-300 ease-in-out',
            styles['base'],
            striped && styles['stripes']
          )}
          style={{
            width: `${(progressPercentage / max) * 100}%`
          }}
        />
      </div>
    </div>
  );
}

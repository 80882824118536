import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from './Backdrop';

/* eslint-disable react/prop-types */
const ModalOverlay = ({ showModal, className, children }) => (
  <CSSTransition in={showModal} timeout={200} classNames="modal" mountOnEnter unmountOnExit>
    <div className="fixed inset-0 flex items-center justify-center z-[1030]">
      <div
        className={`${className} relative rounded-md overflow-y-auto
        h-fit max-h-[90vh] w-[95%] w-[88%] sm:w-4/5 md:w-[70%] lg:w-[80%] xl:w-[90%] 2xl:w-[40.5%]
        bg-white transition-all duration-200 box-shadow opacity-100 z-[1030]`}>
        <div className="content">{children}</div>
      </div>
    </div>
  </CSSTransition>
);

const portalElement = document.getElementById('overlay');

/* eslint-disable react/prop-types */
const Modal = (props) => (
  <>
    {ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment
      <Backdrop onClick={props.onClose} {...props} />,
      portalElement
    )}

    {ReactDOM.createPortal(
      // eslint-disable-next-line react/destructuring-assignment, react/jsx-props-no-spreading
      <ModalOverlay className={props.className} {...props}>
        {props.children}
      </ModalOverlay>,
      portalElement
    )}
  </>
);

export default Modal;

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Selector from '../components/atoms/Selector';
import { Cast, Director, Writer } from '../components/icons/icons';
import { SpinnerSmall } from '../components/spinner/Spinner';
import Card from '../components/UI/Card';
import useListStore, { LIST_SECTIONS } from '../store/list-generator';
import { apiInstance } from '../utils/apiInstance';
import { generateObjectId } from '../utils/generateMongoID';
import { LIST_TYPES } from './ListGeneratorHome';
import AddVersionModal from './VersionModal';
export function getCharacterName(character) {
  const gender = character.gender?.[0];
  return `${character.name} (${gender}/${character.age})`;
}
export function mapCharacter(character) {
  const characterName = getCharacterName(character);
  const uniqueId = character.id || characterName;
  return {
    _id: uniqueId,
    name: characterName,
    data: { _id: uniqueId, ...character }
  };
}
const ListGeneratorSelectors = ({ children, submissions, submissionsLoading, description }) => {
  const location = useLocation();
  const [dropdown, setDropdown] = useListStore((store) => [store.dropdown, store.setDropdown]);
  const resetState = useListStore((store) => store.resetState);
  const resetIdeaState = useListStore((store) => store.resetIdeaState);
  const parentId = useListStore((store) => store.parent_id);
  const prepopulateData = useListStore((store) => store.prepopulateData);
  const [versionModal, setVersionModal] = useState(false);
  const [lists, setLists] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [newId, setNewId] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await apiInstance(location).get('list/all', {
          params: {
            project: dropdown.project?._id,
            type: dropdown.type?._id,
            cast: dropdown?.cast?._id,
            character: dropdown?.character?._id
          }
        });

        const lists = data?.data?.map((d) => ({ _id: d._id, name: d.version }));
        if (
          lists?.length &&
          dropdown.type &&
          dropdown.project &&
          ((dropdown.type?._id === 'cast' && dropdown?.character) || dropdown?.type?._id !== 'cast')
        ) {
          if (newId) {
            setDropdown('version', lists?.find((l) => l._id === newId) || null);
          } else if (!dropdown.version) {
            setDropdown('version', lists?.[0] || null);
          }
        }

        if (
          !lists.length &&
          dropdown.type?._id &&
          dropdown.project?._id &&
          !dropdown.version &&
          ((dropdown.type?._id === 'cast' && dropdown?.character) || dropdown?.type?._id !== 'cast')
        ) {
          const response = await apiInstance(window.location)
            .post('/list/upsert-list/', {
              list_id: generateObjectId(),
              project: dropdown.project._id,
              character: dropdown.character?.data,
              type: dropdown.type?._id,
              sections: LIST_SECTIONS()
            })
            .catch((err) => {
              console.error(err, 'UPSERT-LIST');
            });
          const listData = response?.data?.data;
          if (listData) {
            const item = listData?._id ? { _id: listData?._id, name: listData?.version } : null;
            setDropdown('version', item);
            setLists((prev) => prev.concat(item));
          } else {
            toast.error('Unable to save the list, please try again');
          }
        }
        setLists(lists);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    dropdown.version?._id,
    dropdown.project?._id,
    dropdown.type?._id,
    dropdown?.character?._id,
    newId
  ]);

  useEffect(() => {
    if (dropdown.version?._id) {
      prepopulateData(dropdown.version?._id, location);
    }
  }, [dropdown.version?._id, location]);

  useEffect(() => {
    if (dropdown?.character?.data?.concept_art_image) {
      setImageLoading(true);
      apiInstance()
        .get('/list/image', {
          params: {
            url: dropdown?.character?.data?.concept_art_image
          },
          responseType: 'blob'
        })
        .then(({ data }) => {
          const imageObjectURL = URL.createObjectURL(data); // Create a URL for the image blob
          setImageSrc(imageObjectURL);
          setImageLoading(false);
        })
        .finally(() => {
          setImageLoading(false);
        })
        .catch((err) => {
          setImageLoading(false);
        });
    }
  }, [dropdown?.character?.data?.concept_art_image]);
  const characters = dropdown?.project?.characters?.map(mapCharacter);
  const renderDescriptionCard = () => {
    if (!description) return null;

    return (
      <Card className="min-h-fit" resetZIndex>
        <div className="flex gap-4">
          {dropdown.character && (
            <div>
              {imageLoading ? (
                <div className="flex items-center justify-center shrink-0 w-[138px] h-[138px]">
                  <SpinnerSmall secondPathColor="#FE6629" className="!h-[64px] !w-[64px]" />
                </div>
              ) : imageSrc ? (
                <img
                  src={imageSrc}
                  className="rounded-md !h-[138px] !w-[138px] object-cover border-[1px] border-[#FFFFFF33]"
                  alt={dropdown?.character?.label}
                />
              ) : null}
            </div>
          )}
          <div className="flex flex-col">
            {dropdown?.character?.name && (
              <span className="font-bold">{dropdown?.character?.name}</span>
            )}
            <p>{description}</p>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <div className="flex w-full justify-end">
        <div className="w-full flex justify-start lg:justify-end items-center gap-4 flex-wrap">
          {dropdown.type?._id &&
          ((dropdown.type?._id === 'cast' && dropdown?.character) ||
            dropdown?.type?._id !== 'cast') ? (
            <Selector
              isLoading={loading || !dropdown.type?._id}
              isDisabled={loading}
              disabled={true}
              isOptionDisabled={(option) => option._id === 'new' && !dropdown.version}
              isClearable
              options={lists.concat({
                _id: 'new',
                name: 'Add Version',
                disabled: dropdown?.version ? false : true
              })}
              placeholder="Version"
              value={dropdown.version}
              onChange={(value) => {
                if (!value) resetState();
                if (value?._id === 'new') {
                  setVersionModal(true);
                } else {
                  resetIdeaState();
                  setNewId(null);
                  setDropdown('version', value);
                }
              }}
              getOptionLabel={(item) => {
                if (item._id === 'new')
                  return (
                    <div className="px-1 py-0">
                      <div
                        className={twMerge(
                          'bg-[#667085] p-1 rounded flex justify-center',
                          item.disabled && 'opacity-30 pointer-events-none'
                        )}>
                        + Version
                      </div>
                    </div>
                  );
                return `V${item.name}`;
              }}
            />
          ) : null}
          {dropdown.type?._id === 'cast' && (
            <Selector
              isDisabled={loading}
              options={characters}
              placeholder="Character"
              value={dropdown.character}
              onChange={(value) => {
                setDropdown('version', null);
                setDropdown('character', value);
                setLists([]);
                setNewId(null);
              }}
            />
          )}

          <Selector
            isDisabled={loading || !dropdown.project}
            options={LIST_TYPES}
            placeholder="Type Of List"
            value={dropdown.type}
            onChange={(value) => {
              setDropdown('character', null);
              setDropdown('version', null);
              setDropdown('type', value);
              setLists([]);
              setNewId(null);
            }}
            getOptionLabel={(item) => {
              return (
                <span className="flex gap-x-2 items-center">
                  {item._id === 'writer' ? (
                    <Writer />
                  ) : item._id === 'director' ? (
                    <Director />
                  ) : (
                    <Cast />
                  )}
                  {item.name}
                </span>
              );
            }}
          />
          <Selector
            isDisabled={loading || submissionsLoading}
            isLoading={loading || submissionsLoading}
            maxWidth="20rem"
            options={submissions}
            placeholder="Project"
            value={dropdown.project}
            onChange={(value) => {
              setDropdown('character', null);
              setDropdown('version', null);
              setDropdown('project', value);
              setLists([]);
              setNewId(null);
            }}
            getOptionLabel={(item) => {
              return (
                item.title.replace(
                  'Download to See Example of Coverage, Please Feel Free to Delete',
                  'Download...'
                ) + ` ${item.characters?.length || 0}`
              );
            }}
          />
          {children}
        </div>
      </div>
      {renderDescriptionCard()}
      {dropdown.version && dropdown.type && parentId && (
        <>
          <AddVersionModal
            dropdownState={dropdown}
            setDropdownState={setDropdown}
            isOpen={versionModal}
            onClose={(id) => {
              setNewId(id);
              setVersionModal(false);
            }}
            submissions={submissions}
            list_id={dropdown.version?._id}
            parent_id={parentId}
          />
        </>
      )}
    </>
  );
};

export default ListGeneratorSelectors;

import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../components/UI/Button';
import { apiInstance } from '../utils/apiInstance';
import { LIST_TYPES } from './ListGeneratorHome';
import { mapCharacter } from './ListGeneratorSelectors';

const AddVersionModal = ({
  isOpen,
  onClose,
  submissions,
  list_id,
  dropdownState: dropdownInitialState,
  setDropdownState: setDropdownGlobalState
}) => {
  const [isLoading, setLoading] = useState(false);

  const [dropdownState, setDropdownState] = useState(dropdownInitialState);
  const characters = dropdownState?.project?.characters?.map(mapCharacter);
  const handleChange = (field, value) => {
    setDropdownState((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'project' && { character: null })
    }));
  };
  const handleNewVersion = async (asNew = false) => {
    try {
      setLoading(true);
      if (dropdownState?.type?._id === 'cast' && !dropdownState?.character?.data)
        throw new Error('Please select a character');
      const { data, status } = await apiInstance(window.location).post(`list/duplicate/`, {
        list_id,
        project_id: dropdownState?.project?._id,
        type_id: dropdownState?.type?._id,
        character_id: dropdownState?.character?.data,
        as_new: asNew
      });
      if (status === 200 && !asNew) {
        toast.success(data?.message);
      } else if (status == 200 && asNew) {
        toast.success('New version added successfully!');
      }
      setLoading(false);
      setDropdownGlobalState('version', null);
      setDropdownGlobalState('project', dropdownState?.project);
      setDropdownGlobalState('type', dropdownState?.type);
      setDropdownGlobalState(
        'character',
        dropdownState?.type?._id === 'cast' ? dropdownState?.character : null
      );
      onClose(data?.data?._id);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  function handleClose() {
    onClose?.();
  }

  const isDisabled = dropdownInitialState.type?._id !== dropdownState.type?._id;
  console.log(isDisabled);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg w-[400px] relative">
        {/* Header */}
        <div
          className="flex justify-between items-center p-4 rounded-t-lg"
          style={{
            background: 'linear-gradient(253.98deg, #E0531A 22.31%, #FE6629 77.69%)'
          }}>
          <h2 className="text-lg font-semibold text-white">Add Version</h2>
          <button onClick={handleClose}>
            {/* <AiOutlineClose className="text-gray-500 hover:text-gray-700" /> */}
          </button>
        </div>

        {/* Form */}
        <div className="mt-4 space-y-4 p-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Project</label>
            <select
              onChange={(e) =>
                handleChange(
                  'project',
                  submissions.find((s) => s._id === e.target.value)
                )
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm text-[#667085] bg-[#F9FAFB]">
              {submissions.map((submission) => (
                <option
                  selected={dropdownState.project?._id === submission?._id}
                  key={submission._id}
                  value={submission._id}>
                  {submission.title.replace(
                    'Download to See Example of Coverage, Please Feel Free to Delete',
                    'Download...'
                  )}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">List Type</label>
            <select
              value={dropdownState.type?._id}
              onChange={(e) =>
                handleChange(
                  'type',
                  LIST_TYPES.find((c) => c._id === e.target.value)
                )
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm text-[#667085] bg-[#F9FAFB]">
              {LIST_TYPES.map((type) => (
                <option
                  selected={dropdownState.type?._id === type?._id}
                  key={type._id}
                  value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {dropdownState.type?._id === 'cast' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Character <span className="text-sm text-gray-500">(If it's actor list)</span>
              </label>
              <select
                value={dropdownState.character?._id}
                onChange={(e) =>
                  handleChange(
                    'character',
                    characters.find((c) => c._id === e.target.value)
                  )
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm text-[#667085] bg-[#F9FAFB]">
                {characters.map((character) => (
                  <option
                    selected={dropdownState.character?._id === character?._id}
                    key={character._id}
                    value={character._id}>
                    {character.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Buttons */}
        <div className="mt-6 flex justify-between p-4">
          <button
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
            onClick={handleClose}>
            Cancel
          </button>
          <div className="flex space-x-2">
            <Button
              loading={isLoading}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              onClick={() => handleNewVersion(true)}>
              Start New
            </Button>
            <Button
              disabled={isDisabled}
              loading={isLoading}
              className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
              onClick={() => handleNewVersion()}>
              Duplicate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVersionModal;

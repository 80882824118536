const steps = [
  {
    id: 'intro',
    attachTo: { element: '.first-step', on: 'bottom' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'first-item shepherd bg-[#FFFFFF] border-none',
    highlightClass: '',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Welcome to FullFrame',
    text: `<div className="flex items-start">
            <span className="flex-1" style="color:black;">FullFrame is a coverage report generator for scripts and books.</span>
            <span className="text-[rgba(0,0,0,0.5)]" style="float:right;color:black;font-size:14px">1/7</span>
          </div>`
  },
  {
    id: 'second-step',
    attachTo: { element: '.coverage-button', on: 'top' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'shepherd second-step bottom-arrow max-width-542 bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Generate a Coverage Report For Your Script',
    text: `<div style="color:black;">
            <p>Upload your submission, sign-up for a subscription, and click generate to create a report.</p>
            <span style="float:right;color:gray;font-size:14px">2/7</span>
          </div>`
  },
  {
    id: 'third-step',
    attachTo: { element: '.book-coverage-btn', on: 'right' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'shepherd bottom-arrow max-width-542 bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Generate a Coverage Report For Your Book',
    text: `<div style="color:black;">
            <p>To generate book coverage, select Book Coverage in the side pane.</p>            
            <span style="float:right;color:gray;font-size:14px">3/7</span>
          </div>`
  },
  {
    id: 'fourth-step',
    attachTo: { element: '.draft-comparison-btn', on: 'right' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'shepherd bottom-arrow max-width-542 bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Compare Drafts',
    text: `<div style="color:black;">
            <p>Here you can upload two drafts of the same script to get a comprehensive list of changes, both in the form of headline changes to the plot, characters, tone, etc. and page-by-page changes.</p>            
            <span style="float:right;color:gray;font-size:14px">4/7</span>
          </div>`
  },
  {
    id: 'fifth-step',
    attachTo: { element: '.change-plan-section', on: 'right' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes:
      'shepherd bottom-arrow arrow-white max-width-542 whitespace-pre-line sm-top sm-left bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Choose the Subscription that Works for You',
    text: `<div style="color:black;" class="text-[black] flex flex-col gap-1.5">
            <p>Depending on your coverage needs, when you sign-up, you can pay-as-you-go or select a monthly subscription that aligns to your coverage needs.</p>
            <div class="flex items-center justify-between gap-2">
              <p>You can always change your plan on our billing screen.</p>
              <span style="float:right;color:gray;font-size:14px">5/7</span>
            </div>
          </div>`
  },
  {
    id: 'sixth-step',
    attachTo: { element: '.material-log', on: 'right' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Next',
        type: 'next'
      }
    ],
    classes:
      'shepherd bottom-arrow arrow-white max-width-542 whitespace-normal sm-top sm-left bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    title: 'Automatically Track Submission Information',
    text: `<div style="color:black;">
              <span>You can use the material log to work with your submissions and see report status. It's automatically updated with coverage details and can be exported as an excel file.</span>
              <span style="float:right;color:gray;font-size:14px">6/7</span>
            </div>`
  },
  {
    id: 'seventh-step',
    attachTo: { element: '.template-section', on: 'right' },
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary transition-all duration-300',
        text: 'Finish',
        type: 'cancel'
      }
    ],
    classes: 'shepherd bottom-arrow max-width-440 bg-[#FFFFFF] border-none',
    highlightClass: 'highlight',
    scrollTo: true,
    cancelIcon: {
      enabled: true,
      className: 'shepherd-cancel-icon-white' // Add this line
    },
    title: 'Upload a Custom Template',
    text: `<div style="color:black;">
              <span>If you'd like to automatically generate reports using your company's template, upload your template through our custom templates page and we can use your preferred format.</span>
              <span style="float:right;color:gray;font-size:14px">7/7</span>
            </div>`
  }
];

export default steps;

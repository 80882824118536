import React from 'react';
import { twMerge } from 'tailwind-merge';
import { SpinnerSmall } from '../spinner/Spinner';

const GrayButton = ({
  text = '',
  icon = <></>,
  onClick = () => {},
  className = '',
  type = 'button',
  selected = false,
  loading = false
}) => {
  const returnBgColor = () => {
    let bgColor = 'bg-[#292A35]';
    if (selected != null) {
      bgColor = `${selected ? 'bg-[#393A4B]' : 'bg-[rgba(57,58,75,0.5)]'}`;
    }
    if (!loading) {
      bgColor += ' hover:bg-[#393A4B]';
    }
    return bgColor;
  };

  const baseClassName = () =>
    twMerge(
      'flex justify-center items-center text-sm gap-2 px-2 md:px-3.5 py-1.5 font-inter border border-[#393A4B] shadow-[0px_1px_1px_rgba(0,0,0,0.15)] rounded transition-all duration-300',
      returnBgColor(),
      className
    );

  return (
    <button type={type} className={baseClassName()} onClick={onClick} disabled={loading}>
      <div className="flex items-center gap-1 h-fit">
        {icon}
        {text}
        {loading && <SpinnerSmall className="!h-4 !w-4" secondPathColor={'#FEF2EF'} />}
      </div>
    </button>
  );
};

export default GrayButton;

import { createContext, useContext, useState } from 'react';

export const SubmissionsContext = createContext({
  submissionsData: [],
  setSubmissionData: () => {},
  hiddenColumns: ['Based on'],
  setHiddenColumns: () => {},
  filters: [],
  setFilters: () => {},
  loading: true,
  setLoading: () => {},
  updateFilters: () => {},
  filteredData: []
});

export const useSubmissionsContext = () => useContext(SubmissionsContext);

export function SubmissionsContextProvider({ children }) {
  const [submissionsData, setSubmissionsData] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(['Based on']);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const setSubmissionData = (data) => {
    setSubmissionsData(data);
  };

  const updateFilters = (type, operator, value) => {
    setFilters((prevFilters) => {
      const existingFilterIndex = prevFilters.findIndex((filter) => filter.type === type);

      if (existingFilterIndex !== -1) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex] = {
          type,
          operator,
          value
        };
        return updatedFilters;
      } else {
        return [...prevFilters, { type, operator, value }];
      }
    });
  };

  return (
    <SubmissionsContext.Provider
      value={{
        submissionsData,
        setSubmissionData,
        hiddenColumns,
        setHiddenColumns,
        filters,
        setFilters,
        updateFilters,
        loading,
        setLoading
      }}>
      {children}
    </SubmissionsContext.Provider>
  );
}

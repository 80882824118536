import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Logo from '../static/images/logo.png';
import StarIcon from './StarIcon';
import Button from '../components/UI/Button';
import { debounce } from 'lodash-es';
import axios from 'axios';

const FeedbackPage = () => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const report_id = queryParams.get('report_id');

  const submitFeedback = async (data) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}submit_feedback/`, data);
    } catch (error) {
      console.error('Submit feedback error:', error);
    }
  };

  const debouncedSubmitFeedback = useCallback(
    debounce((data) => submitFeedback(data), 1000),
    []
  );

  const handleFeedbackSubmit = () => {
    if (!rating || !report_id) {
      return;
    }

    const data = {
      type,
      rating,
      feedback,
      report_id
    };

    debouncedSubmitFeedback(data);
  };

  useEffect(() => {
    handleFeedbackSubmit();
  }, [rating, feedback]);

  return (
    <div className="feedback-component font-inter font-semibold flex items-center justify-center min-h-screen text-white">
      <div className="feedback-back-link absolute top-6 left-6">
        <Link to="/" className="text-sm p-2 rounded bg-[#1D2939] hover:text-white">
          &larr; Go Back Fullr.me
        </Link>
      </div>

      <div className="feedback-container backdrop-blur-[10px] font-serif pt-10 rounded-lg relative h-auto w-full max-w-4xl flex flex-col items-center justify-center border-2 border-solid border-[#1D2939]">
        <div className="feedback-header h-16 flex items-center justify-center mb-4">
          <img src={Logo} alt="Fullframe" className="cursor-pointer" />
          <h1 className="text-xl sm:text-2xl md:text-3xl mb-4 ml-2">Share Your Experience</h1>
        </div>

        <div className="feedback-prompt h-24 flex items-center justify-center mb-4 text-center px-2">
          <h1 className="text-lg sm:text-xl md:text-2xl">How would you rate the report quality?</h1>
        </div>
        <div
          className="feedback-stars bg-gradient-to-b from-[rgba(254,102,41,0.048)] to-[rgba(254,102,41,0.22)]
          h-32 sm:h-40 w-full rounded-lg border border-solid border-[#1D2939] flex justify-center items-center space-x-4 sm:space-x-6 md:space-x-10">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => setRating(star)}
              onMouseEnter={() => setHoverRating(star)}
              onMouseLeave={() => setHoverRating(0)}
              className="feedback-star cursor-pointer">
              <StarIcon rating={hoverRating || rating} star={star} />
            </span>
          ))}
        </div>

        <div
          className={`feedback-comment bg-gradient-to-b from-[rgba(254,102,41,0.048)] to-[rgba(254,102,41,0.22)] backdrop-blur-[0px] w-full px-4 transition-opacity duration-700 ${
            !rating ? 'opacity-0 pointer-events-none h-0' : 'mt-6 opacity-100'
          }`}>
          <div className="feedback-comment-prompt mb-4">
            <h1 className="text-lg md:text-xl px-4">What could be improved about the report?</h1>
          </div>

          <div className="feedback-comment-box">
            <textarea
              style={{ resize: 'none' }}
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full p-4 bg-[#101828] rounded-xl text-white border border-solid border-[#344054]"
              placeholder="Write your feedback here..."></textarea>
            <div className="feedback-submit-btn flex justify-end">
              <Button className="my-4 w-28" onClick={() => navigate('/login')}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;

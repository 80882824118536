import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import redirectToLogin from '../utils/token';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { state } = location;

    if (state?.prevLandingPage) {
      // Redirect to Azure AD B2C login page
      redirectToLogin();
    } else {
      navigate('/');
    }
  }, []);

  return null;
};

export default Login;

import {
  DirectorIcon,
  GenreIcon,
  LoglineIcon,
  NotesIcon,
  PriorityIcon,
  ProducersIcon,
  ResponseStatusIcon,
  SubmittedByIcon,
  TitleIcon,
  WriterIcon
} from '../components/icons/icons';

export const headings = [
  {
    label: '',
    name: 'type',
    customHeadingClass: 'w-7 !pr-2'
  },
  {
    label: 'Title',
    name: 'title',
    sortable: true
  },
  {
    label: 'Writers/Directors/Producers',
    name: 'Writer/Directors/Producers'
  },
  {
    label: 'Submitted by',
    name: 'submittedBy'
  },
  {
    label: 'Genres',
    name: 'genre'
  },
  {
    label: 'Logline',
    name: 'logline'
  },
  {
    label: 'Note',
    name: 'note'
  },
  {
    label: 'Based on',
    name: 'based_on'
  },
  {
    label: 'Read',
    name: 'read',
    sortable: true
  },
  {
    label: 'Responded',
    name: 'response_status',
    sortable: true
  },
  {
    label: 'Priority',
    name: 'priority',
    sortable: true
  },
  {
    label: 'Date',
    name: 'created_at',
    sortable: true
  },
  {
    label: '',
    name: 'actions'
  }
];

const iconClass = 'inline-block mr-3';
export const filterItems = [
  {
    label: 'Title',
    icon: <TitleIcon className={iconClass} />
  },
  {
    label: 'Writer',
    icon: <WriterIcon className={iconClass} />
  },
  {
    label: 'Director',
    icon: <DirectorIcon className={iconClass} />
  },
  {
    label: 'Prodcuers',
    icon: <ProducersIcon className={iconClass} />
  },
  {
    label: 'Submitted by',
    icon: <SubmittedByIcon className={iconClass} />
  },
  {
    label: 'Genres',
    icon: <GenreIcon className={iconClass} />
  },
  {
    label: 'Logline',
    icon: <LoglineIcon className={iconClass} />
  },
  {
    label: 'Notes',
    icon: <NotesIcon className={iconClass} />
  },
  {
    label: 'Response Status',
    icon: <ResponseStatusIcon className={iconClass} />
  },
  {
    label: 'Priority',
    icon: <PriorityIcon className={iconClass} />
  }
];

import React from 'react';

const StarIcon = ({ rating, star }) => {
  return (
    <svg
      className="w-10 h-10  md:w-[61px] md:h-[61px] sm:w-10 sm:h-10 "
      viewBox="0 0 65 62"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.4104 5.23071L43.4534 23.5535L63.6737 26.4918L49.0421 40.7541L52.4961 60.8927L34.4104 51.3846L16.3248 60.8927L19.7788 40.7541L5.14722 26.4918L25.3675 23.5535L34.4104 5.23071Z"
        stroke="url(#paint0_linear_16105_1511)"
        fill={star <= rating ? 'url(#paint0_linear_16105_1511)' : 'none'}
        stroke-width="4.61538"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16105_1511"
          x1="63.6737"
          y1="60.8927"
          x2="25.4344"
          y2="72.436"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#CD4610" />
          <stop offset="1" stop-color="#FE6629" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StarIcon;

import { debounce } from 'lodash-es';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { MEDIA_TYPES, STATUS } from '../constants';
import { apiInstance } from '../utils/apiInstance';
import { generateObjectId } from '../utils/generateMongoID';
import isUUID from '../utils/isUuid';
export const LIST_SECTIONS = () => [
  {
    id: generateObjectId(),
    version: 1,
    title: 'Top Picks',
    name: 'top',
    talents: []
  }
];

const debouncedTalentUpsert = debounce(
  (body) =>
    apiInstance(window.location)
      .post('/list/upsert-talent/', body)
      .catch((error) => {
        console.log(error, 'TALENT UPSERT ERROR');
      }),
  2000
);

export const debouncedListUpsert = debounce(
  (body) =>
    apiInstance(window.location)
      .post('/list/upsert-list/', body)
      .catch((error) => {
        console.log(error, 'LIST UPSERT ERROR');
      }),
  2000
);

function mapSections(sections) {
  if (!sections || !sections?.length) return LIST_SECTIONS();

  return sections?.map((section) => ({
    ...section,
    talents: section?.talents?.map((talent) => ({
      ...talent,
      status: {
        name: STATUS.find((s) => s.id === talent.availability)?.name,
        type: STATUS.find((s) => s.id === talent.availability)
      },
      credits: talent?.credits?.map((credit) => ({
        ...credit,
        type: MEDIA_TYPES.find((mt) => mt.id === credit.type),
        name: credit.name
      })),
      upcoming_projects: talent?.upcoming_projects?.map((project) => ({
        ...project,
        type: MEDIA_TYPES.find((mt) => mt.id === project.type),
        name: project.name
      }))
    }))
  }));
}

export const INITIAL_IDEA_STATE = {
  suggestions: [],
  intent: '',
  traits: null
};
// Define the Zustand store
const useListStore = create(
  persist(
    (set, get) => ({
      ideaState: INITIAL_IDEA_STATE,
      parent_id: null,
      version: null,
      list_id: generateObjectId(),
      sections: LIST_SECTIONS(),
      dropdown: {
        type: null,
        project: null,
        version: null,
        character: null
      },
      isLoading: false,
      resetIdeaState: () => {
        set((state) => {
          return {
            ...state,
            ideaState: INITIAL_IDEA_STATE
          };
        });
      },
      setIdeaState: (updater) => {
        set((state) => {
          const currentValue = state.ideaState;
          const newValue = typeof updater === 'function' ? updater(currentValue) : updater;

          return {
            ...state,
            ideaState: newValue
          };
        });
      },
      resetState: () => {
        set((state) => {
          return {
            parent_id: null,
            list_id: generateObjectId(),
            sections: LIST_SECTIONS(),
            isLoading: false,
            dropdown: {
              ...state.dropdown,
              version: null
            }
          };
        });
      },
      prepopulateData: async (_id, location) => {
        set({ isLoading: true });
        try {
          const { data } = await apiInstance(location).get('list/detail', {
            params: {
              list_id: _id
            }
          });
          set((state) => {
            const sections = data?.data?.sections;
            return {
              ...state,
              sections: mapSections(sections),
              list_id: data?.data?._id,
              parent_id: data?.data?.parent_id,
              version: data?.data?.version
            };
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      setDropdown: (key, value) =>
        set((state) => ({
          dropdown: {
            ...state.dropdown,
            [key]: value
          }
        })),
      addTalent: async (sectionId, talentInput, debounce = true) => {
        try {
          const talent = {
            ...talentInput,
            _id: generateObjectId(),
            status: { type: STATUS[1], name: STATUS[1]?.name }
          };

          set((state) => {
            const sectionIndex = state.sections.findIndex((section) => section.id === sectionId);
            if (sectionIndex === -1) return state;

            const newSections = [...state.sections];
            newSections[sectionIndex].talents = [...newSections[sectionIndex].talents, talent];
            return { sections: newSections };
          });

          const talentPayload = {
            talent_id: talent._id,
            ...talentInput,
            credits:
              talentInput?.credits?.map((credit) => ({
                type: credit?.type?.id || credit?.type,
                name: credit?.name
              })) || [],
            upcoming_projects:
              talentInput?.upcoming_projects?.map((proj) => ({
                type: proj?.type?.id || proj?.type,
                name: proj?.name
              })) || []
          };

          const listPayload = {
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          };

          if (debounce) {
            // Use debounced API calls
            debouncedTalentUpsert(talentPayload);
            debouncedListUpsert(listPayload);
          } else {
            // Immediate API calls
            await apiInstance(window.location)
              .post('/list/upsert-talent/', talentPayload)
              .catch((error) => console.error('Immediate TALENT UPSERT ERROR:', error));

            await apiInstance(window.location)
              .post('/list/upsert-list/', listPayload)
              .catch((error) => console.error('Immediate LIST UPSERT ERROR:', error));
          }
        } catch (error) {
          console.error('Error adding talent:', error);
        }
      },
      addSection: async (section) => {
        try {
          set((state) => ({
            sections: [
              ...state.sections,
              {
                ...section,
                id: generateObjectId(),
                title: `Section ${state.sections.length + 1}`,
                talents: [
                  {
                    credits: [],
                    upcoming_projects: []
                  }
                ]
              }
            ]
          }));

          debouncedListUpsert({
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            version: get()?.version,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error adding section:', error);
        }
      },
      editSection: async (sectionId, updatedSection) => {
        try {
          set((state) => {
            const sectionIndex = state.sections.findIndex((section) => section.id === sectionId);
            if (sectionIndex === -1) return state;

            const newSections = [...state.sections];
            newSections[sectionIndex] = { ...newSections[sectionIndex], ...updatedSection };

            return { sections: newSections };
          });

          debouncedListUpsert({
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error editing section:', error);
        }
      },
      removeSection: async (sectionId) => {
        try {
          set((state) => {
            const newSections = state.sections.filter((section) => section.id !== sectionId);
            return { sections: newSections };
          });

          debouncedListUpsert({
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error removing section:', error);
        }
      },
      moveTalent: async (sourceSectionId, targetSectionId, talentId, position = 0) => {
        try {
          set((state) => {
            const sourceSectionIndex = state.sections.findIndex(
              (section) => section.id === sourceSectionId
            );
            const targetSectionIndex = state.sections.findIndex(
              (section) => section.id === targetSectionId
            );

            if (sourceSectionIndex === -1 || targetSectionIndex === -1) return state;

            const sourceSection = state.sections[sourceSectionIndex];
            const targetSection = state.sections[targetSectionIndex];

            const talentIndex = sourceSection.talents.findIndex(
              (talent) => talent._id === talentId
            );
            if (talentIndex === -1) return state;

            const talent = sourceSection.talents[talentIndex];
            const newSourceTalents = sourceSection.talents.filter((t) => t._id !== talentId);
            const newTargetTalents = [
              ...targetSection.talents.slice(0, position),
              talent,
              ...targetSection.talents.slice(position)
            ];

            const newSections = [...state.sections];
            newSections[sourceSectionIndex] = { ...sourceSection, talents: newSourceTalents };
            newSections[targetSectionIndex] = { ...targetSection, talents: newTargetTalents };

            return { sections: newSections };
          });

          debouncedListUpsert({
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error moving talent:', error);
        }
      },
      removeTalent: async (sectionId, talentId) => {
        try {
          set((state) => {
            const sectionIndex = state.sections.findIndex((section) => section.id === sectionId);
            if (sectionIndex === -1) return state;

            const section = state.sections[sectionIndex];
            const newTalents = section.talents.filter((talent) => talent._id !== talentId);

            const newSections = [...state.sections];
            newSections[sectionIndex] = { ...section, talents: newTalents };

            return { sections: newSections };
          });

          debouncedListUpsert({
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error removing talent:', error);
        }
      },
      editTalent: async (sectionId, talentId, updatedTalent) => {
        try {
          debouncedTalentUpsert({
            talent_id: isUUID(talentId) ? undefined : talentId,
            avatar: updatedTalent?.avatar,
            name: updatedTalent.name,
            notes: updatedTalent.notes,
            availability: updatedTalent.status?.type?.id,
            is_favourite: updatedTalent?.is_favourite,
            credits: updatedTalent?.credits?.map((credit) => ({
              type: credit?.type?.id || credit?.type,
              name: credit?.name
            })),
            upcoming_projects: updatedTalent?.upcoming_projects?.map((proj) => ({
              type: proj?.type?.id || proj?.type,
              name: proj?.name
            })),
            company_name: updatedTalent?.company_name,
            submitted_by: updatedTalent?.submitted_by
          });

          set((state) => {
            const sectionIndex = state.sections.findIndex((section) => section.id === sectionId);
            if (sectionIndex === -1) return state;

            const section = state.sections[sectionIndex];
            const newTalents = section.talents.map((talent) =>
              talent._id === talentId ? { ...talent, ...updatedTalent } : talent
            );

            const newSections = [...state.sections];
            newSections[sectionIndex] = { ...section, talents: newTalents };

            return { sections: newSections };
          });
          debouncedListUpsert({
            version: get().version,
            list_id: isUUID(get().list_id) ? null : get().list_id,
            parent_id: get().parent_id,
            version: get()?.version,
            project: get().dropdown.project._id,
            character: get().dropdown.character?.data,
            type: get().dropdown.type._id,
            sections: get().sections.map((s) => ({
              id: s.id,
              title: s.title,
              talents: s.talents.map((t) => t._id).filter((t) => t)
            }))
          });
        } catch (error) {
          console.error('Error editing talent:', error);
        }
      }
    }),
    {
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ dropdown: state.dropdown })
    }
  )
);

export default useListStore;

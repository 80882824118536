import React, { useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import DataTable from '../components/DataTable/DataTable';
import Divider from '../components/Divider/Divider';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import EditableText from '../components/atoms/EditableText';
import PlusIcon from '../components/icons/PlusIcon';
import ReCycleIcon from '../components/icons/ReCycleIcon';
import DropdownNew from '../components/molecules/DropdownNew';
import { MEDIA_TYPES, STATUS } from '../constants';
import userAvatar from '../static/icons/avatar.png';
import useListStore from '../store/list-generator';
const IdeaGeneratorMembers = ({ talents, isIdeaLoading, onRefreshIdeas, onSkip }) => {
  const textRef = useRef();
  const [addTalent, firstSectionId] = useListStore((store) => [
    store.addTalent,
    store.sections?.[0]?.id
  ]);
  const title = useListStore((store) => store.dropdown.type?.name);
  const version = useListStore((store) => store.dropdown.version);
  useEffect(() => {
    if (title) textRef?.current?.setText(title);
  }, [title]);

  function handleAdd(index, result) {
    addTalent(
      firstSectionId,
      {
        _id: result?._id,
        name: result?.name,
        avatar: `https://image.tmdb.org/t/p/original/${result?.profile_path}`,
        credits: result?.projects?.past_projects,
        upcoming_projects: result?.projects?.upcoming_projects,
        availability: STATUS[1]?.id
      },
      false
    );
    onSkip?.(index);
    toast.success('Talent added to the selected list!');
  }

  console.log(talents);
  const COLUMNS = useMemo(() => {
    return [
      {
        accessor: 'profile_path',
        Header: 'Headshot',
        Cell({ row: { original }, value }) {
          return (
            <div style={{ height: 40, width: 40 }}>
              <img
                src={value ? `https://image.tmdb.org/t/p/original/${value}` : userAvatar}
                onError={(e) => (e.currentTarget.src = userAvatar)}
                className="rounded-md border border-gray-100 h-full w-full object-cover cursor-pointer"
                alt={original?.name}
              />
            </div>
          );
        }
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell({ value }) {
          return <EditableText initialText={value} disableEditing />;
        }
      },
      {
        accessor: 'projects.past_projects',
        Header: 'Credits',
        minWidth: '17vw',
        Cell({ value }) {
          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {value?.map?.((credit, index) => {
                return (
                  <DropdownNew
                    disableEditing
                    searchPlaceholder="Type.."
                    className={'min-h-[25px] h-auto'}
                    items={MEDIA_TYPES}
                    value={credit}
                    key={index}
                  />
                );
              })}
            </div>
          );
        }
      },
      {
        accessor: 'projects.upcoming_projects',
        Header: 'Next Up Project',
        minWidth: '17vw',
        Cell({ value }) {
          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {value?.map?.((project, index) => {
                return (
                  <DropdownNew
                    disableEditing
                    searchPlaceholder="Type.."
                    className={'bg-orange-400 min-h-[25px] h-auto'}
                    items={MEDIA_TYPES}
                    value={project}
                    key={index}
                  />
                );
              })}
            </div>
          );
        }
      },
      {
        accessor: 'imdb_id',
        Header: 'Add To Main List',
        Cell({ row: { original, index }, value }) {
          return (
            <div className="flex justify-center items-center gap-2">
              <div className="flex gap-4">
                <Button onClick={() => handleAdd(index, original)} disabled={!version}>
                  <PlusIcon />
                  Add
                </Button>
                <Button
                  onClick={() => onSkip?.(index, value)}
                  variant="secondary"
                  className={'rounded-sm bg-gray-800 p-2 hover:opacity-60 border border-black-50'}>
                  Skip
                </Button>
              </div>
            </div>
          );
        }
      }
    ];
  }, [talents?.map((t) => t._id)?.join('-')]);

  return (
    <Card className={'min-h-fit !p-0'} resetZIndex>
      <header className="p-4 flex justify-between items-center">
        <EditableText key={title} initialText={title} fullWidth ref={textRef} disableEditing />
        <Button onClick={onRefreshIdeas} disabled={isIdeaLoading}>
          <ReCycleIcon />
          Refresh Ideas
        </Button>
      </header>
      <Divider className="my-0" />
      <section>
        <DataTable data={talents} columns={COLUMNS} />
      </section>
    </Card>
  );
};

export default IdeaGeneratorMembers;
